import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"

import CrossFade from "../components/CrossFade/cross-fade"
import { useOnLoadImages } from "../hooks/useOnLoadImages"
import Preloader from "../components/preloader"

declare global {
  interface Window {
    gtag: any
  }
}

const IndexPage: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const imagesLoaded = useOnLoadImages(wrapperRef)

  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "/",
        page_path: "/",
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      prismicHomepage {
        data {
          images {
            project_image {
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  `)
  const images = data.prismicHomepage.data.images.map(image => {
    return image.project_image.fluid
  })
  return (
    <>
      {!imagesLoaded && <Preloader />}
      <Layout>
        <div ref={wrapperRef}>
          <CrossFade slides={images} autoPlayTimer={2} />
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
