import React from "react"
import clsx from "clsx"
import Img from "gatsby-image"

interface CrossFadeSlideProps {
  image: any
  isActive: boolean
}

const CrossFadeSlide: React.FC<CrossFadeSlideProps> = ({ image, isActive }) => {
  return (
    <div
      className={clsx(
        "absolute bg-cover bg-no-repeat bg-center transition-opacity duration-700 w-full h-full",
        isActive ? "opacity-100" : "opacity-0"
      )}
    >
      <Img
        className="absolute w-full h-full object-cover pointer-events-none"
        fluid={image}
      />
    </div>
  )
}

export default CrossFadeSlide
