import React, { useState, useRef, useEffect } from "react"
import { useSwipeable } from "react-swipeable"
import { isMobile } from "react-device-detect"

import CrossFadeSlide from "./cross-fade-slide"

interface CrossFadeProps {
  slides: any[]
  autoPlayTimer: number
}

const CrossFade: React.FC<CrossFadeProps> = ({ slides, autoPlayTimer }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [isFirstAutoPlay, setIsFirstAutoPlay] = useState(true)
  //used to control cursor
  const [mouseHoverRight, setMouseHoverRight] = useState(false)
  const crossFaderRef = useRef<HTMLDivElement>(null)
  const autoPlayRef = useRef<() => void>(null!)
  const shouldAutoPlay = useRef<boolean>(false)
  const autoPlayInterval = useRef<number>()
  const resumeAutoPlayTimeout = useRef<number>()

  const getMousePosInterval = useRef<number>()
  const mousePos = useRef<number>()
  const currentSlide = useRef(0)

  // setup ref for your usage
  const swipeRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (autoPlayTimer > 0) {
      shouldAutoPlay.current = true
    }

    const autoPlay = () => {
      nextSlide()
    }

    if (shouldAutoPlay.current) {
      autoPlayInterval.current = window.setInterval(
        autoPlay,
        autoPlayTimer * 1000
      )
    }
    return () => {
      clearInterval(autoPlayInterval.current)
    }
  }, [])

  const nextSlide = () => {
    currentSlide.current >= slides.length - 1
      ? (currentSlide.current = 0)
      : (currentSlide.current += 1)
    setActiveSlide(currentSlide.current)
  }

  const prevSlide = () => {
    currentSlide.current <= 0
      ? (currentSlide.current = slides.length - 1)
      : (currentSlide.current -= 1)
    setActiveSlide(currentSlide.current)
  }

  const handleSwipeLeft = () => {
    resetAutoPlay()
    nextSlide()
  }

  const handleSwipeRight = () => {
    resetAutoPlay()
    prevSlide()
  }

  const detectMouseLeftOrRight = () => {
    const rect = document.body.getBoundingClientRect()
    if (mousePos.current! > rect.width / 2) {
      //Mouse is left
      setMouseHoverRight(true)
    } else setMouseHoverRight(false)
    return
  }

  const onHover = e => {
    detectMouseLeftOrRight()
    getMousePosInterval.current = window.setInterval(
      detectMouseLeftOrRight,
      0.05 * 1000
    )
  }

  const onExitHover = () => {
    clearInterval(getMousePosInterval.current)
  }

  const handleClick = () => {
    if (isMobile && isFirstAutoPlay) {
      resetAutoPlay()
      nextSlide()
      setIsFirstAutoPlay(false)
      return
    }
    resetAutoPlay()
    if (mouseHoverRight) {
      nextSlide()
    } else {
      prevSlide()
    }
    return
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipeLeft(),
    onSwipedRight: () => handleSwipeRight(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    delta: 5,
  })

  const refPassthrough = el => {
    // call useSwipeable ref prop with el
    handlers.ref(el)

    // set myRef el so you can access it yourself
    swipeRef.current = el
  }

  const resetAutoPlay = () => {
    clearInterval(autoPlayInterval.current)
    const autoPlay = () => {
      nextSlide()
    }
    autoPlayInterval.current = window.setInterval(
      autoPlay,
      autoPlayTimer * 1000
    )
  }

  return (
    <div {...handlers} ref={refPassthrough}>
      <div
        className={`h-full w-full m-0 overflow-hidden absolute ${
          mouseHoverRight ? "right-arrow" : "left-arrow"
        }`}
        onClick={handleClick}
        ref={crossFaderRef}
        onMouseOver={e => onHover(e)}
        onMouseOut={onExitHover}
        onMouseMove={e => (mousePos.current = e.clientX)}
      >
        <div className="relative w-full h-full">
          {slides.map((slide, i) => {
            return (
              <CrossFadeSlide
                key={i}
                image={slide}
                isActive={i === activeSlide}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CrossFade
